import React from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';


export default function FoodOrderStatus({value}) {
    const { t } = useTranslation();

    console.log('FoodOrderStatus');

    return (
        <Box>
            {value.status === 'cancel' &&
            <Box mb={2}>
                <Alert severity="error">{t('order.canceled')}</Alert>
            </Box>
            }
            {value.status === 'received' &&
            <Box mb={2}>
                <Alert severity="success">{t('order.customerReceived')}</Alert>
            </Box>
            }
        </Box>
    )
};