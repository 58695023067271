import React, {useState, useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {httpClient} from "../../core/HttpClient";
import {TextI18n, getTextI18n} from "../TextI18n";

const useStyles = makeStyles({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    }
});

export default function Register() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [account, setAccount] = useState({});

    console.log('[Register]');
    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/riders/register/' + token)
            .then(res => {
                if(res.data && res.status === 200){
                    setAccount(res.data);
                } else {
                    setInvalid(true);
                }
                setLoading(false);
            }).catch(err => {
            setInvalid(true);
            setLoading(false);
        });

    }, [token]);

    const handleSubmitRegister = () => {
        let data = {
            riderToken: token
        };
        setUpdating(true);
        httpClient.post(process.env.REACT_APP_API_BASE_URL + '/riders/register', data)
            .then(res => {
                if(res.data){
                    setAccount(res.data);
                }
                setUpdating(false);
            });
    };

    return (
        <Box>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <>
                <Box>
                    <Typography variant="h6">
                        {t('register.title')}
                    </Typography>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}}/>
                {invalid &&
                <Box mb={2}>
                    <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
                </Box>
                }
                {!invalid &&
                <Card sx={{borderRadius: '0px'}} className={classes.root} mb={2}>
                    {account.image &&
                    <CardMedia
                        style={{cursor: 'pointer'}}
                        className={classes.media}
                        image={`${process.env.REACT_APP_CDN_BASE_URL}/${account.image.permission}/o/${account.image.name}`}
                        title={getTextI18n(account.name)}
                    />
                    }
                    {!account.image &&
                    <CardMedia
                        className={classes.media}
                        image="https://cdn.foodkub.com/public/assets/empty.png"
                    />
                    }
                    <Box p={2}>
                        <Typography component={'span'} variant="h6">
                            <TextI18n value={account.name}></TextI18n>
                        </Typography>
                    </Box>
                    {account.registered &&
                    <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                        <CheckCircleOutlineIcon color="success" sx={{fontSize: 60}}/>
                        <Typography variant="body1" color="inherit">{t('register.registered')}</Typography>
                    </Box>
                    }
                    {!account.registered &&
                    <Button variant="contained"
                            size="large"
                            color="secondary"
                            disabled={updating}
                            fullWidth={true}
                            onClick={handleSubmitRegister}
                            style={{fontWeight: 'bold'}}>
                        <span>{t('register.confirm')}</span>
                    </Button>
                    }
                </Card>
                }
            </>
            }
        </Box>
    );
}
