import React, { useState, useRef, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NavigationIcon from '@mui/icons-material/Navigation';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const redIcon = new L.Icon({
    iconUrl:
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
    iconSize: [21, 34],
    iconAnchor: [10, 34]
});

const LocationMarker = (props) => {
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={redIcon}></Marker>

    )
};

export default function AddressDelivery({open, location, shop, onClose}) {
    const { t } = useTranslation();
    console.log('[AddressDialog]');

    const handleNavigation = (lat, lng) => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&dir_action=navigate`, '_blank');
    };

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.delivery')}
                    </Typography>
                    <IconButton edge="end" onClick={(e) => handleNavigation(location.coordinates.lat, location.coordinates.lng)}>
                        <NavigationIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MapContainer center={[location.coordinates.lat, location.coordinates.lng]}
                          zoom={location.zoom}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this} position={[location.coordinates.lat, location.coordinates.lng]} />
                {shop && shop.lat && shop.lng &&
                <Circle
                    center={{lat: shop.lat, lng:shop.lng}}
                    color="#1abc9c"
                    fillColor="#1abc9c"
                    radius={shop.distance * 1000}/>
                }

            </MapContainer>
        </Dialog>
    )
};