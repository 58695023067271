import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FoodOrderTimeLine from "../FoodOrderTimeLine";
import FoodOrderStatus from "../FoodOrderStatus";
import {httpClient} from "../../core/HttpClient";

export default function FoodOrderDone() {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [foodOrder, setFoodOrder] = useState(null);
    console.log('FoodOrderDone');
    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/riders/orders/'+id)
            .then(res => {
                if(res.data && res.status === 200){
                    setFoodOrder(res.data);
                }
            }).catch(err => {
        });
    }, [id]);

    const handleBack = () => {
        history.push('/home');
    };

    return (
        <div>
            <Box display="flex" alignItems="center">
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {t('orders.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {foodOrder &&
            <>
                <FoodOrderStatus value={foodOrder} />
                <FoodOrderTimeLine value={foodOrder} />
            </>
            }
        </div>
    );
}
