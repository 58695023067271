import React from 'react';
import { Route, Router } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import Home from './components/Home';
import Profile from './components/Profile';
import Register from './components/Register';
import RegisterInit from './components/RegisterInit';
import LandingPage from './components/LandingPage';
import Shops from './components/Shops';
import Orders from './components/Orders';
import FoodOrder from './components/FoodOrder';
import FoodOrderDone from './components/FoodOrderDone';
import Login from './components/Login';
import SignIn from './components/SignIn';
import Blank from './layouts/Blank';
import Default from './layouts/Default';

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Blank path="/login" component={Login} />
            <Blank path="/signin" component={SignIn} />
            <Default path="/shops" component={Shops} />
            <Default path="/orders/done/:id" component={FoodOrderDone} />
            <Default path="/orders/:id" component={FoodOrder} />
            <Default path="/orders" component={Orders} />
            <Default path="/home" component={Home} />
            <Default path="/landing/orders/:id" component={FoodOrder} />
            <Default path="/landing/home" component={Home} />
            <Route path="/landing/register/:token" component={RegisterInit} />
            <Route path="/landing/register" component={RegisterInit} />
            <Route path="/landing" component={LandingPage} />
            <Default path="/profile" component={Profile} />
            <Default path="/register/:token" component={Register} />
            <Default path="/" component={Home} />
            <Redirect to="/" />
        </Switch>
    </Router>
);

export default Routes;