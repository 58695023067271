import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Redirect, Route, useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SettingsIcon from '@mui/icons-material/Settings';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ContactDialog from "../../components/ContactDialog";
import FooterMenu from "../../components/FooterMenu";
import { Context } from "../../core/Context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 0,
    },
    leftMenuButton: {
        marginTop: '1rem',
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    showMenu: {
        display: 'inline'
    },
    hideMenu: {
        display: 'none'
    },
    content: {
        marginTop: '10px',
        marginBottom: '80px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    select: {
        paddingRight: '0!important',
        paddingLeft: '20px',
        color: 'white',
        "& .MuiSvgIcon-root": {
            color: "white",
        },
        "& option": {
            color: "black"
        }
    },
    icon:{
        left:-5,
        color: 'white',
    },
    iconOpen:{
        transform:'none'
    },

}));

const DEFAULT_PROFILE_PICTURE = 'https://cdn.foodkub.com/public/assets/empty_user.svg';
// const liff = window.liff;

const Default = ({children}) => {
    const { t } = useTranslation();
    const [context, setContext] = useState({displayName: '', mobile: '', pictureUrl: DEFAULT_PROFILE_PICTURE, success: false, fail: false, loading: false});
    const history = useHistory();
    const classes = useStyles();
    const [openContact, setOpenContact] = useState(false);

    useEffect(() => {
        // let token = localStorage.getItem('token');
        // if(!token){
        //     history.push('/signin');
        // }

        // initial login line liff
        // const liffId = process.env.REACT_APP_LINE_LIFF_ID;
        // liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});

        let rider = localStorage.getItem('rider');
        if(rider){
            let _rider = JSON.parse(rider);
            let displayName = _rider.displayName;
            let pictureUrl = _rider.pictureUrl;
            let mobile = _rider.mobile;
            if(!mobile){
                setOpenContact(true);
            }
            setContext({displayName: displayName, pictureUrl: pictureUrl, mobile: mobile, success: false, fail: false, loading: false});
        }


    }, [history]);

    const handleProfile = async() => {
        history.push('/profile');
    };

    const handleCloseContact = (value) => {
        setContext({...context, displayName: value.displayName, mobile: value.mobile});
        setOpenContact(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, openMap: false, success: false, fail: false, warning: false });
    };

    return (
        <div className='page page-dashboard'>
            <CssBaseline />
            <header>
                <AppBar elevation={0} position="static">
                    <Toolbar>
                        <Avatar edge="start"  alt={context.displayName} src={context.pictureUrl} />
                        <Typography variant="h6" noWrap={true} className={classes.title} ml={1} style={{flexGrow: 1}}>
                            <span>{context.displayName}</span>
                        </Typography>
                        <IconButton edge="end" aria-label="setting"
                                    onClick={handleProfile}>
                            <SettingsIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </header>
            <div className={classes.content}>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            {openContact &&
            <ContactDialog open={openContact} onClose={handleCloseContact} />
            }
            <FooterMenu />
            <Snackbar open={context.success}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar open={context.fail}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity="error">
                    {t('common.message.saveFail')}
                </Alert>
            </Snackbar>
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={context.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
};

const DefaultLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('token');
                if (token) {
                    return <Default><Component {...props} /></Default>
                } else {
                    return <Redirect to={
                        {
                            pathname: '/signin',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default DefaultLayoutRoute;