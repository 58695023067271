import React, { useState, useEffect, useReducer, useRef  } from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {TimeFromNow} from "../TimeFromNow";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import TakeOrdersDialog from "../TakeOrdersDialog";
import {httpClient} from "../../core/HttpClient";


function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.mode==='query'?false:action.payload.length > 0,
                hits: action.page===1?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(accounts, page, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        let results = [];

        const url = process.env.REACT_APP_API_BASE_URL + '/riders/orders';
        const result = await httpClient({
                method: 'post',
                url: url,
                data: {
                    accounts: accounts
                },
                cancelToken
            }
        );

        if(result.data){
            results = result.data;
        }
        dispatch({ type: "FETCH_SUCCESS", payload: results, page: page })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

async function updateHits(hits, dispatch) {
    dispatch({ type: "FETCH_START" });
    try {
        dispatch({ type: "FETCH_SUCCESS", payload: hits, page: 1 })
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE" })
    }
}

let activeAccounts = [];

export default function Orders() {
    const { t } = useTranslation();
    const [page] = useState(1);
    const [foodOrder, setFoodOrder] = useState(null);
    const [{ hits, hasError, isLoading }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
    });

    const debouncedFetchHits = useRef(
        _.debounce(
            (accounts, page, cancelToken) => fetchHits(accounts, page, dispatch, cancelToken),
            500
        )
    ).current;

    const debouncedUpdateHits = useRef(
        _.debounce(
            (hits) => updateHits(hits, dispatch),
            1
        )
    ).current;

    useEffect(() => {
        console.log('Orders');
        const { cancel, token } = httpClient.CancelToken.source();
        debouncedFetchHits(activeAccounts, page, token);
        return () => cancel("No longer latest query") || debouncedFetchHits.cancel()
    }, [debouncedFetchHits, debouncedUpdateHits, page]);

    const handleCloseDialog = (value) =>{
        if(value){
            let newHits = hits.filter(function( obj ) {
                return obj._id !== value.foodOrderId;
            });
            debouncedUpdateHits(newHits);
        }
        setFoodOrder(null);
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">
                    {t('orders.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('orders.notAvailable')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <List className="SortableList">
                {hits.map((value, index) => (
                    <ListItem key={`item-${value._id}`} divider={index !== hits.length - 1 ? true : false} button
                              component={Link} to={`/orders/${value._id}`}>
                        <ListItemAvatar>
                            <Avatar
                                src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer}`}></Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <div>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography noWrap={true}>
                                            <span>
                                                {value.location.contact && value.location.contact.name}
                                            </span>
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography noWrap={true}>
                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                            {value.orderNo}
                                        </span>
                                    </Typography>
                                    <div>
                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                            <TimeFromNow value={value.timeLine.orders}/>
                                        </span>
                                    </div>
                                </Box>
                            </div>
                        </ListItemText>
                        <Badge color="primary" variant="dot" invisible={value.status !== 'pending'}
                               style={{top: '-28px', right: '-8px'}}></Badge>
                    </ListItem>
                ))}
            </List>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {foodOrder &&
                <TakeOrdersDialog onClose={handleCloseDialog} value={foodOrder} />
            }
        </div>
    );
}
