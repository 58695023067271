import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../core/HttpClient";
import { Context } from "../../core/Context";

const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function Profile() {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [context, setContext] = useContext(Context);
    const [profile, setProfile] = useState({displayName: '', mobile: ''});
    const [error, setError] = useState({displayName: false, mobile: false});

    console.log('Profile', currentLanguage);
    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/riders/profile')
            .then(res => {
                if(res.data && res.status === 200){
                    setProfile(res.data);
                }
            }).catch(err => {
        });
    }, []);

    const handleSave = () => {
        if(_validation()){
            const url = process.env.REACT_APP_API_BASE_URL + '/riders/profile';
            httpClient.put(url, profile)
                .then(res => {
                    if(res.data){
                        let rider = {displayName: res.data.displayName, mobile: res.data.mobile, pictureUrl: res.data.pictureUrl};
                        localStorage.setItem('rider', JSON.stringify(rider));
                        let _newContext = { ...context, displayName: rider.displayName, mobile: rider.mobile, success: true};
                        setContext(_newContext);
                    }
                    i18n.changeLanguage(currentLanguage);
                }).catch(e=>{
                console.log(e);
                let _newContext = { ...context,fail: true};
                setContext(_newContext);
            });
        }
    };

    const handleChange = ({ target }) => {
        setProfile({ ...profile, [target.name]: target.value });
    };

    function _validation() {
        let result = true;
        let error = {displayName: false, mobile: false};
        if(!profile.displayName){
            result = false;
            error.displayName = true;
        }
        if(!profile.mobile ||
            profile.mobile.length !== 10 ||
            !profile.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    const handleChangeLanguage = ({ target }) => {
        setCurrentLanguage(target.value);
    };

    return (
        <div>
            <Box>
                <Typography variant="h6">
                    {t('profile.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box mt={2}>
                <TextField id="profileName"
                           label={t('profile.displayName')}
                           variant="outlined"
                           fullWidth={true}
                           error={error.displayName}
                           InputLabelProps={{
                               shrink: true,
                           }}
                           inputProps={{
                               autoComplete: 'new-password',
                           }}
                           name="displayName" value={ profile.displayName } onChange={ handleChange } required />
            </Box>
            <Box mt={2}>
                <TextField id="profileMobile"
                           label={t('profile.mobile')}
                           variant="outlined"
                           fullWidth={true}
                           name="mobile" type="tel"
                           value={profile.mobile}
                           onChange={ handleChange }
                           error={error.mobile}
                           InputLabelProps={{
                               shrink: true,
                           }}
                           InputProps={{
                               inputComponent: PhoneFormatCustom,
                           }}
                           required />
            </Box>
            <Box mt={2}>
                <FormControl variant="outlined"
                             fullWidth={true}>
                    <InputLabel id="language-select-filled-label">{t('profile.language')}</InputLabel>
                    <Select
                        native
                        labelId="language-select-filled-label"
                        label={t('profile.language')}
                        name="language"
                        onChange={handleChangeLanguage}
                        value={currentLanguage}>
                        <option value="en-US">{t('languages.english')}</option>
                        <option value="th-TH">{t('languages.thai')}</option>
                    </Select>
                </FormControl>
            </Box>
            <Box mt={2}>
                <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                    {t('common.save')}
                </Button>
            </Box>
        </div>
    );
}
