import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OnlineStatus from '../OnlineStatus'
import DoneTasks from '../DoneTasks'

export default function Home() {
    const { t } = useTranslation();
    console.log('Home');
    useEffect(() => {

    }, []);

    return (
        <div>
            <Box>
                <Typography variant="h6">
                    {t('home.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card elevation={1}>
                        <OnlineStatus />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card elevation={1}>
                        <DoneTasks />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
