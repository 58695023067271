import React, { useState }  from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {httpClient} from "../../core/HttpClient";


export default function TakeOrdersDialog({value, onClose}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    console.log('[TakeOrdersDialog]');

    const handleTakeOrders = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/riders/orders/take';
        let data = {foodOrderId: value._id};
        httpClient.post(url, data)
            .then(res => {
                onClose({foodOrderId: value._id, status: 'success'});
            }).catch(e=>{
                onClose({foodOrderId: value._id, status: 'fail'});
            });
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog open={value!==null}
                onClose={e=>onClose(0)}
                fullWidth={true}>
            <AppBar elevation={0} position="static" color="light" sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {t('common.confirm')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Box>
                    <Typography sx={{ flex: 1 }} variant="body1" component="div">
                        {t('orders.takeThisOrders')}
                    </Typography>
                </Box>
            </DialogContent>
            <Box p={2}>
                <Box>
                    <Button autoFocus variant="contained" fullWidth={true} size="large"
                            disabled={loading}
                            disableElevation onClick={handleTakeOrders}>
                        {t('orders.takeOrders')}
                    </Button>
                </Box>
                <Box mt={1}>
                    <Button autoFocus variant="outlined" fullWidth={true} size="large"
                            disabled={loading}
                            disableElevation onClick={handleCancel}>
                        {t('orders.noThanks')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
};