import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../core/HttpClient";


export default function MoneyDialog({value, open, onClose}) {
    const { t } = useTranslation();
    console.log('[MoneyDialog]');

    const handleSave = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/riders/orders/'+value._id;
        httpClient.put(url, {step: 4})
            .then(res => {
                onClose(1);
            }).catch(e=>{
            console.log(e);
        });
    };

    return (
        <Dialog open={open}
                onClose={e=>onClose(0)}
                fullWidth={true}>
            <AppBar elevation={0} position="static" color="light" sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {t('order.confirmPayment')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Box display="flex" justifyContent="center">
                    {value.payment.method === 'cash' &&
                    <Typography variant="h5" color="red">
                        <strong><NumberFormat value={value.grandTotal} displayType={'text'} thousandSeparator={true} /> {t('common.baht')}</strong>
                    </Typography>
                    }
                    {value.payment.method !== 'cash' &&
                    <Typography variant="h5" color="green">
                        <strong>{t(`payment.success`)}</strong>
                    </Typography>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                    {t('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
};