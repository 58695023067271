import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { green } from '@mui/material/colors';
import NumberFormat from 'react-number-format';
import {DateTime} from "../DateTime";
import {httpClient} from "../../core/HttpClient";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        background: '#ececec',
        padding: '5px'
    }
}));

export default function OnlineStatus() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [accumulation, setAccumulation] = useState({received: 0, delivering: 0});

    useEffect(() => {
        console.log('OnlineStatus');
        let url = process.env.REACT_APP_API_BASE_URL + '/riders/task/count';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccumulation(res.data);
                }
            });
    }, []);

    return (
        <div className={classes.root}>
            <Box className={classes.title}
                 display="flex"
                 alignItems="center"
                 justifyContent="center">
                <DateTime value={new Date()} format="DD MMM YYYY" />
            </Box>
            <Box display="flex" justifyContent="space-between" pb={1}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center" mt={1} mb={1}>
                            <EmojiFlagsIcon fontSize="large" style={{ color: green[500] }}  />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h5">
                                <NumberFormat value={accumulation.received} displayType={'text'} thousandSeparator={true} />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center">
                            <Typography style={{fontSize: '0.8rem'}}>
                                {t('onlineStatus.todayDeliver')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center" mt={1} mb={1}>
                            <AvTimerIcon style={{color: '#1565c0'}} fontSize="large" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h5">
                                <NumberFormat value={accumulation.delivering} displayType={'text'} thousandSeparator={true} />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="center">
                            <Typography style={{fontSize: '0.8rem'}}>
                                {t('onlineStatus.waitDeliver')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}