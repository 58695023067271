import React, { useState, useEffect, useReducer, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import {useHistory} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {httpClient} from "../../core/HttpClient";
import {TextI18n, getTextI18n} from "../TextI18n";

const liff = window.liff;

const useStyles = makeStyles({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    }
});

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.mode==='query'?false:action.payload.length > 0,
                hits: action.page===1?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, page, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        let results = [];

        const url = process.env.REACT_APP_API_BASE_URL + '/riders/shops';
        const result = await httpClient(
            `${url}?p=${page}`,
            {
                cancelToken,
            }
        );

        if(result.data && result.data.accounts){
            results = result.data.accounts;
        }
        dispatch({ type: "FETCH_SUCCESS", payload: results, page: page })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}


export default function Shops() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [query] = useState('');
    const [page, setPage] = useState(1);
    const [{ hits, hasNext, hasError, isLoading }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
    });

    const debouncedFetchHits = useRef(
        _.debounce(
            (query, page, cancelToken) => fetchHits(query, page, dispatch, cancelToken),
            500
        )
    ).current;

    useEffect(() => {
        console.log('Shops');
        const { cancel, token } = httpClient.CancelToken.source();
        debouncedFetchHits(query, page, token);
        return () => cancel("No longer latest query") || debouncedFetchHits.cancel()
    }, [debouncedFetchHits, query, page]);

    const handleFetchData = () =>{
        let p = page;
        setPage(++p); // trig page for reload data by useReducer
    };

    const handleScanQRCode = async() => {
        try{
            const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            await liff.init({ liffId: liffId }).catch(err=>{throw err});
            if (liff.isLoggedIn()) {
                liff.scanCodeV2().then(result => {
                    if(result && result.value){
                        const a = result.value.split('/');
                        const code = a[a.length-1];
                        history.push(`/register/${code}`);
                    }
                })
            } else {
                // external browser
                console.log('no logged in');
            }
        }catch(e){console.log(e)}

    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">
                    {t('shop.title')}
                </Typography>
                <IconButton onClick={(e) => handleScanQRCode()}>
                    <QrCodeScannerIcon />
                </IconButton>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('common.notFound')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <InfiniteScroll
                dataLength={hits.length} //This is important field to render the next data
                next={handleFetchData}
                hasMore={hasNext}
            >
                <Box style={{background: 'white'}}>
                    <List className={classes.root} subheader={<li/>}>
                        {hits.map((item) => (
                            <ListItem key={`item-${item._id}`}>
                                {item &&
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        alt={getTextI18n(item.name)}
                                        src={`${process.env.REACT_APP_CDN_BASE_URL}/${item.image.permission}/s/${item.image.name}`}
                                    />
                                </ListItemAvatar>
                                }
                                <ListItemText>
                                    <div>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography>
                                                <TextI18n value={item.name}></TextI18n>
                                            </Typography>
                                        </Box>
                                    </div>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </InfiniteScroll>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    );
}
